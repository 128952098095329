import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import { CForm, CFormLabel, CFormInput, CButton, CFormCheck } from '@coreui/react';
import Select from 'react-select'

const urlRouter = '/users';

const getBusyList = [
  { value: 'Зайнятість', label: 'Виберіть зайнятість', isDisabled: true },
  { value: 'Працюю', label: 'Працюю' },
  { value: 'Не працюю', label: 'Не працюю' }
];

const ageList = [
  { value: 'Вік', label: 'Виберіть вік', isDisabled: true },
  { value: '18-30', label: '18-30' },
  { value: '30-50', label: '30-50' },
  { value: '50-60', label: '50-60' }
];

const anotherProgramList = [
  { value: 'Виберіть', label: 'Виберіть', isDisabled: true },
  { value: 'Так', label: 'Так' },
  { value: 'Ні', label: 'Ні' },
];

const genderList = [
  { value: 'Стать', label: 'Виберіть стать', isDisabled: true },
  { value: 'Чоловік', label: 'Чоловік' },
  { value: 'Жінка', label: 'Жінка' },
];

const addressFromList = [
  { value: 'Виберіть область', label: 'Виберіть область', isDisabled: true },
  { value: 'Вінницька', label: 'Вінницька' },
  { value: 'Волинська', label: 'Волинська' },
  { value: 'Дніпропетровська', label: 'Дніпропетровська' },
  { value: 'Донецька', label: 'Донецька' },
  { value: 'Житомирська', label: 'Житомирська' },
  { value: 'Закарпатська', label: 'Закарпатська' },
  { value: 'Запорізька', label: 'Запорізька' },
  { value: 'Івано-Франківська', label: 'Івано-Франківська' },
  { value: 'Київська', label: 'Київська' },
  { value: 'Кіровоградська', label: 'Кіровоградська' },
  { value: 'Луганська', label: 'Луганська' },
  { value: 'Львівська', label: 'Львівська' },
  { value: 'Миколаївська', label: 'Миколаївська' },
  { value: 'Одеська', label: 'Одеська' },
  { value: 'Полтавська', label: 'Полтавська' },
  { value: 'Рівненська', label: 'Рівненська' },
  { value: 'Сумська', label: 'Сумська' },
  { value: 'Тернопільська', label: 'Тернопільська' },
  { value: 'Харківська', label: 'Харківська' },
  { value: 'Херсонська', label: 'Херсонська' },
  { value: 'Хмельницька', label: 'Хмельницька' },
  { value: 'Черкаська', label: 'Черкаська' },
  { value: 'Чернівецька', label: 'Чернівецька' },
  { value: 'Чернігівська', label: 'Чернігівська' },
  { value: 'Автономна Республіка Крим', label: 'Автономна Республіка Крим' },
];


// Компонент для створення (оновлення) користувача з типом "ВПО"
const UsersCreateFormVPO = (props) => {
  // console.log('props', props);
  // console.log('props.data', props.data);

  const objOrderData = {
    username: '',
    firstName: '',
    lastName: '',
    plainPassword: '',
    phone: '',
    employed: '',
    email: '',
    age: '',
    exDirector: '',
    currentDirector: '',
    gender: '',
    address: '',
    addressFrom: '',
    supportType: [],
    roles: [],
    userType: props.userType,
    anotherProgram: '',
  };

  // Чи обов'язково заповнювати поле з перолем 
  const isRequiredPassword = (props.data == undefined) ? true : false;
  // Значення, які передадуться в api
  const [values, setValues] = useState(objOrderData);
  // Значення для коректного відображення Select
  const [selectItems, setSelectItems] = useState({
    employed: getBusyList[0],
    age: ageList[0],
    gender: genderList[0],
    addressFrom: addressFromList[0],
    anotherProgram: anotherProgramList[0],
  });
  // Для відслідковування checkbox для "Матеріальної підтримки"
  const [isMentoringSupport, setIsMentoringSupport] = useState((props.data != undefined && props.data.supportType.length > 0) ? true : false);

  // useEffect(() => {
  // console.log('values', values);
  // console.log('selectItems', selectItems);
  // }, [values, selectItems]);


  // Для відслідковування чи потрібна підтримка
  useEffect(() => {
    // console.log('isMentoringSupport', isMentoringSupport);
    if (isMentoringSupport) {
      setValues({
        ...values,
        // "supportType": ["Матеріальна"]
        supportType: ["Матеріальна"]
      });
    } else {
      setValues({
        ...values,
        // "supportType": []
        supportType: []
      });
    }
  }, [isMentoringSupport]);

  // Заповнити поля при users/update/
  useEffect(() => {
    if (props.data != undefined) {
      setValues({
        ...values,
        username: props.data.username,
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        plainPassword: props.data.plainPassword,
        phone: props.data.phone,
        employed: props.data.employed,
        email: props.data.email,
        age: props.data.age,
        exDirector: props.data.exDirector,
        currentDirector: props.data.currentDirector,
        gender: props.data.gender,
        address: props.data.address,
        addressFrom: props.data.addressFrom,
        supportType: props.data.supportType,
        roles: props.data.roles,
        userType: props.userType,
        anotherProgram: props.data.anotherProgram,
        active: 'on',
      });

      let employedInit = getBusyList.map((v, k) => {
        if (props.data.employed == v.value)
          return v;
      });
      let ageInit = ageList.map((v, k) => {
        if (props.data.age == v.value)
          return v;
      });
      let genderInit = genderList.map((v, k) => {
        if (props.data.gender == v.value)
          return v;
      });
      let addressFromInit = addressFromList.map((v, k) => {
        if (props.data.addressFrom == v.value)
          return v;
      });
      let anotherProgramInit = anotherProgramList.map((v, k) => {
        if (props.data.anotherProgram == v.value)
          return v;
      });

      setSelectItems({
        ...selectItems,
        employed: employedInit,
        age: ageInit,
        gender: genderInit,
        addressFrom: addressFromInit,
        anotherProgram: anotherProgramInit,
      });
    }
  }, [props.data]);

  // Функція для зміни значень Input
  const handleInputChange = (e) => {
    // console.log(e);
    // console.log("e.target", e.target);
    // const { name, value, type } = e.target;
    const { name, value } = e.target;

    if (name === "email") {
      // console.log('name', name);
      // для дублювання username(login) з email
      setValues({
        ...values,
        [name]: value,
        username: value
      })
    } else {
      setValues({
        ...values,
        // [name]: type === "number" ? parseFloat(value, 10) : value
        [name]: value,
      });
    };
  };

  // Функція для зміни значень Select
  const handleChange = (value, name) => {
    // console.log('value', value);

    if (name === 'supportType') {
      // if (name === 'roles') {
      let supports = [];
      value.map((role) => {
        supports.push(support.value);
      });
      setValues({
        ...values,
        // [name]: [value.label]
        [name]: supports
      });
    } else {
      setValues({
        ...values,
        [name]: value.value
      });
    }

    setSelectItems({
      ...selectItems,
      [name]: value
    });
  };

  // Функція для відправки форми
  const handleSubmit = event => {
    event.preventDefault();

    let v = true;
    let vError = '';

    if (values.age == '') {
      v = false;
      vError += "-> Вік\n";
    }
    if (values.gender == '') {
      v = false;
      vError += "-> Стать\n";
    }
    if (values.employed == '') {
      v = false;
      vError += "-> Зайнятість\n";
    }
    if (values.addressFrom == '') {
      v = false;
      vError += "-> Область з якої переїхали\n";
    }
    if (values.anotherProgram == '') {
      v = false;
      vError += "-> Чи користуєтесь іншими програмами\n";
    }

    if (!v) {
      alert("Ви не заповнили обовязкові поля:\n" + vError);
    }

    // Для надсилання запиту для створення користувача
    if (v) {
      // console.log('values post', values);

      // Передати дані про користувача на верх для запиту на створення 
      props.setDataToApi(values);
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3 mt-4">
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">username</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" name='username' value={values.username} onChange={handleInputChange} required />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" name='email' value={values.email} onChange={handleInputChange}
            pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
            title="Будь ласка, введіть коректний Email у форматі mail@gmail.com і тільки латинськими літерами"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" name='firstName' value={values.firstName} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Прізвище</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" name='lastName' value={values.lastName} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">plainPassword</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput5" name='plainPassword' value={values.plainPassword} onChange={handleInputChange} required={isRequiredPassword} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" name='phone' value={values.phone} onChange={handleInputChange}
            pattern="^\d{10}$|^\d{12}$"
            title="Будь ласка, введіть номер телефону у форматі 10 або 12 цифр"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput8">Вік</CFormLabel>
          <Select options={ageList} value={selectItems.age} onChange={(value) => { handleChange(value, 'age') }} />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput9">Стать</CFormLabel>
          <Select options={genderList} value={selectItems.gender} onChange={(value) => { handleChange(value, 'gender') }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput10">Зайнятість</CFormLabel>
          <Select options={getBusyList} value={selectItems.employed} onChange={(value) => { handleChange(value, 'employed') }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput11">Попередній роботодавець</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput11" name='exDirector' value={values.exDirector} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput12">Діючий роботодавець</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput12" name='currentDirector' value={values.currentDirector} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput13">Адреса</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput13" name='address' value={values.address} onChange={handleInputChange} required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput14">Область з якої переїхали</CFormLabel>
          <Select options={addressFromList} value={selectItems.addressFrom} onChange={(value) => { handleChange(value, 'addressFrom') }} />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput15">Чи користуєтесь іншими програмами</CFormLabel>
          <Select options={anotherProgramList} value={selectItems.anotherProgram} onChange={(value) => { handleChange(value, 'anotherProgram') }} />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput16" label="Матеріальна підтримка" checked={isMentoringSupport} onChange={() => setIsMentoringSupport(!isMentoringSupport)} />
        </div>


        {/* <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(props.data != undefined) ? props.data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format tools',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default UsersCreateFormVPO;