import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import Select from 'react-select'
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import UsersFormLviv from 'src/components/users/UsersFormLviv';
import UsersFormVPO from 'src/components/users/UsersFormVPO';
import UsersFormBusiness from 'src/components/users/UsersFormBusiness';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/users';
const urlRouter = '/users';
const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));


export async function LoaderUsersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}


const UsersUpdateForm = () => {

  const navigate = useNavigate();
  const { data } = useLoaderData();
  // Дані для відправлення на api
  const [dataToApi, setDataToApi] = useState(false);
  const editorRef = useRef(null);
  const [showModalMedia, setShowModalMedia] = useState(true);
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Дані користувача оновлено";
  const popupTextWarning = "Дані користувача не оновлено";

  // console.log('data', data);
  // console.log('dataToApi', dataToApi);

  // Запит для для оновлення даних користувача
  useEffect(() => {
    // console.log('dataToApi', dataToApi);
    if (dataToApi != false) {

      AxiosInstance.put(`${urlApi}/${data.id}`, dataToApi).then((response) => {
        if (response.status === 200) {
          // alert("Дані користувача оновлено");
          // Вивести popup про успішне оновлення даних
          popup.toastifySuccess(popupTextSuccess);
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          // alert("Дані користувача не оновлено");
          popup.toastifyWarning(popupTextWarning);
        }
      });
    }
  }, [dataToApi]);


  // const actionShowModalMedia = (rez) => {
  //   setShowModalMedia(rez);
  //   console.log(11111);
  //   if (!rez)
  //     getMedia();
  // };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* {(data != undefined) &&
        <div className="mb-4">
          {
            (data.mediaObjects != undefined && data.mediaObjects.length > 0) &&
            <img src={process.env.REACT_APP_SERVER_URL + data.mediaObjects[0].contentUrl} alt="blog-image" height={100} className='m-5' />
          }

          <BasicModal
            show={showModalMedia}
            actionShowModal={actionShowModalMedia}
            title={`Додати зображення користувача`}
            btn_name='Додати зображення'
            content={
              <MediaFrom user_id={data.id} actionShowModal={actionShowModalMedia} />
            }
          />
        </div>
      } */}

      {
        (data != undefined) &&
        <>
          {/* Тип користувача "Мешканець Львівщини" */}
          {
            (data.userType === "Lviv" || data.userType === undefined) &&
            <>
              <h2>Мешканець Львівщини</h2>
              <UsersFormLviv userType={data.userType} data={data} setDataToApi={setDataToApi} role={role} />
            </>
          }

          {/* Тип користувача "ВПО" */}
          {
            (data.userType === "Vpo") &&
            <>
              <h2>ВПО</h2>
              <UsersFormVPO userType={data.userType} data={data} setDataToApi={setDataToApi} />
            </>
          }

          {/* Тип користувача "Бізнес" */}
          {
            (data.userType === "business") &&
            <>
              <h2>Бізнес</h2>
              <UsersFormBusiness userType={data.userType} data={data} setDataToApi={setDataToApi} />
            </>
          }
        </>
      }
    </>
  );
};

export default UsersUpdateForm;