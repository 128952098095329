import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import { CForm, CFormLabel, CFormInput, CButton, CFormCheck } from '@coreui/react';
import Select from 'react-select'

const urlRouter = '/users';

const grantSupportList = [
  { value: 'Виберіть', label: 'Виберіть', isDisabled: true },
  { value: 'Так', label: 'Так' },
  { value: 'Ні', label: 'Ні' },
];

const regionFromList = [
  { value: 'Виберіть область', label: 'Виберіть область', isDisabled: true },
  { value: 'Вінницька', label: 'Вінницька' },
  { value: 'Волинська', label: 'Волинська' },
  { value: 'Дніпропетровська', label: 'Дніпропетровська' },
  { value: 'Донецька', label: 'Донецька' },
  { value: 'Житомирська', label: 'Житомирська' },
  { value: 'Закарпатська', label: 'Закарпатська' },
  { value: 'Запорізька', label: 'Запорізька' },
  { value: 'Івано-Франківська', label: 'Івано-Франківська' },
  { value: 'Київська', label: 'Київська' },
  { value: 'Кіровоградська', label: 'Кіровоградська' },
  { value: 'Луганська', label: 'Луганська' },
  { value: 'Львівська', label: 'Львівська' },
  { value: 'Миколаївська', label: 'Миколаївська' },
  { value: 'Одеська', label: 'Одеська' },
  { value: 'Полтавська', label: 'Полтавська' },
  { value: 'Рівненська', label: 'Рівненська' },
  { value: 'Сумська', label: 'Сумська' },
  { value: 'Тернопільська', label: 'Тернопільська' },
  { value: 'Харківська', label: 'Харківська' },
  { value: 'Херсонська', label: 'Херсонська' },
  { value: 'Хмельницька', label: 'Хмельницька' },
  { value: 'Черкаська', label: 'Черкаська' },
  { value: 'Чернівецька', label: 'Чернівецька' },
  { value: 'Чернігівська', label: 'Чернігівська' },
  { value: 'Автономна Республіка Крим', label: 'Автономна Республіка Крим' },
];

// Компонент для створення (оновлення) користувача з типом "Бізнес"
const UsersFormBusiness = (props) => {

  const objOrderData = {
    username: '',
    firstName: '',
    plainPassword: '',
    regionFrom: '',
    phone: '',
    email: '',
    numberFOP: '',
    codeEDRPOU: '',
    grantSupport: '',
    companyName: '',
    userType: props.userType,
  };

  // Чи обов'язково заповнювати поле з перолем 
  const isRequiredPassword = (props.data == undefined) ? true : false;
  // Значення, які передадуться в api
  const [values, setValues] = useState(objOrderData);
  // Значення для коректного відображення Select
  const [selectItems, setSelectItems] = useState({
    regionFrom: regionFromList[0],
    grantSupport: grantSupportList[0],
  });

  // useEffect(() => {
  // console.log('values', values);
  // console.log('selectItems', selectItems);
  // }, [values, selectItems]);

  // Заповнити поля при users/update/
  useEffect(() => {
    if (props.data != undefined) {
      setValues({
        ...values,
        username: props.data.username,
        firstName: props.data.firstName,
        plainPassword: props.data.plainPassword,
        regionFrom: props.data.regionFrom,
        phone: props.data.phone,
        email: props.data.email,
        numberFOP: props.data.numberFOP,
        codeEDRPOU: props.data.codeEDRPOU,
        grantSupport: props.data.grantSupport,
        companyName: props.data.companyName,
        active: 'on',
      });

      let regionFromInit = regionFromList.map((v, k) => {
        if (props.data.regionFrom == v.value)
          return v;
      });
      let grantSupportInit = grantSupportList.map((v, k) => {
        if (props.data.grantSupport == v.value)
          return v;
      });

      setSelectItems({
        ...selectItems,
        regionFrom: regionFromInit,
        grantSupport: grantSupportInit,
      });
    }
  }, [props.data]);

  // Функція для зміни значень Input
  const handleInputChange = (e) => {
    // console.log(e);
    // console.log("e.target", e.target);
    // const { name, value, type } = e.target;
    const { name, value } = e.target;

    if (name === "email") {
      // console.log('name', name);
      // для дублювання username(login) з email
      setValues({
        ...values,
        [name]: value,
        username: value
      })
    } else {
      setValues({
        ...values,
        // [name]: type === "number" ? parseFloat(value, 10) : value
        [name]: value,
      });
    };
  };

  // Функція для зміни значень Select
  const handleChange = (value, name) => {
    // console.log('value', value);
    setValues({
      ...values,
      [name]: value.value
    });

    setSelectItems({
      ...selectItems,
      [name]: value
    });
  };

  // Функція для відправки форми
  const handleSubmit = event => {
    event.preventDefault();

    let v = true;
    let vError = '';

    if (values.regionFrom == '') {
      v = false;
      vError += "-> Область з якої переїхали\n";
    }
    if (values.grantSupport == '') {
      v = false;
      vError += "-> Чи користувались грантовою підтримкою бізнесу?\n";
    }

    if (!v) {
      alert("Ви не заповнили обовязкові поля:\n" + vError);
    }

    // Для надсилання запиту для створення користувача
    if (v) {
      // console.log('values post', values);

      // Передати дані про користувача на верх для запиту на створення 
      props.setDataToApi(values);
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3 mt-4">
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">username</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" name='username' value={values.username} onChange={handleInputChange} required />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" name='email' value={values.email} onChange={handleInputChange}
            pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
            title="Будь ласка, введіть коректний Email у форматі mail@gmail.com і тільки латинськими літерами"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я контактної особи</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" name='firstName' value={values.firstName} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">plainPassword</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput4" name='plainPassword' value={values.plainPassword} onChange={handleInputChange} required={isRequiredPassword} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" name='phone' value={values.phone} onChange={handleInputChange}
            pattern="^\d{10}$|^\d{12}$"
            title="Будь ласка, введіть номер телефону у форматі 10 або 12 цифр"
            required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">Податковий номер платника податків (ФОПа)</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput17" name='numberFOP' value={values.numberFOP} onChange={handleInputChange}
            pattern="^\d{10}$"
            title="Будь ласка, введіть номер у форматі 10 цифр"
            required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">Код ЄДРПОУ</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput8" name='codeEDRPOU' value={values.codeEDRPOU} onChange={handleInputChange}
            pattern="^\d{8}$"
            title="Будь ласка, введіть код у форматі 8 цифр"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput9">Чи користувались грантовою підтримкою бізнесу?</CFormLabel>
          <Select options={grantSupportList} value={selectItems.grantSupport} onChange={(value) => { handleChange(value, 'grantSupport') }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput10">Назва компанії</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput10" name='companyName' value={values.companyName} onChange={handleInputChange} required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput11">Область з якої переїхали</CFormLabel>
          <Select options={regionFromList} value={selectItems.regionFrom} onChange={(value) => { handleChange(value, 'regionFrom') }} />
        </div>

        {/* <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format tools',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default UsersFormBusiness;