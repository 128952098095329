import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { cilPen, cilPlus, cilTrash, cilFile } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const urlApi = '/form_answers';
const url = 'https://dev-api-lez.inneti.net';

export async function LoaderFormAnswerItem({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
            return response;
        });
        return res;
    }
    return false;
};


// Компонент заповненої форм
const FormAnswerItem = () => {

    const { data } = useLoaderData();
    // console.log('data', data);

    // Додані взяті з API
    const [dataFormAnswer, setDataFormAnswer] = useState(false);
    const [dataAnswerFields, setDataAnswerFields] = useState([]);
    const [media, setMedia] = useState();

    // Взяти необідні дані, які прийшли з сервера
    useEffect(() => {
        if (data !== undefined) {
            setDataFormAnswer(data.form);
            setDataAnswerFields(data.formAnswerFields);
        };
        if (data.media.length > 0) {
            setMedia(data.media);
        };
    }, [data]);

    // useEffect(() => {
    //     console.log('media', media);
    // }, [media]);

    // useEffect(() => {
    //     console.log('dataFormAnswer', dataFormAnswer);
    // }, [dataFormAnswer]);

    // useEffect(() => {
    //     console.log('dataAnswerFields', dataAnswerFields);
    // }, [dataAnswerFields]);

    return (
        <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
            <div className="mb-4">
                <h2>{dataFormAnswer.name}</h2>
                <p>{dataFormAnswer.description}</p>
            </div>

            {/* Заповнена форма */}

            {
                (Array.isArray(dataAnswerFields) && dataAnswerFields.length > 0) &&
                dataAnswerFields.map((field) => {
                    // console.log('field', typeof(field.value));
                    const value = (field.value !== "") ? field.value : "Не заповнено";
                    return (
                        <div key={field.id} className="mb-3">
                            <h4>{field.formField.label}</h4>
                            <p>{value}</p>
                        </div>
                    )
                })
            }

            {/* Медіа */}
            {
                (media !== undefined && media.length > 0) &&
                <div className='mb-3'>
                    <h4 className='mb-3 d-flex'>
                        <span>Прикріплений файл</span>
                        <Link className='ms-3' target='_blank' to={`${url}${media[0].contentUrl}`} preventScrollReset={true}>
                            <CButton color="dark" variant="outline" className='d-flex align-items-center'>
                                <CIcon icon={cilFile} customClassName="nav-icon" height={20} />
                            </CButton>
                        </Link>
                    </h4>
                </div>
            }
        </div>
    );
};

export default FormAnswerItem;