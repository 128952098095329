import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import ProductCategories from 'src/components/product/ProductCategories';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import MyToastify from 'src/components/myComponents/MyToactify';
import 'react-toastify/dist/ReactToastify.css';
import PagesCategories from 'src/components/pages/PagesCategories';
import { Alert } from 'react-bootstrap';

///api/pages/id
const urlApi = '/pages';
const urlRouter = '/site-pages';

export async function LoaderPagesUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const pagesForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [title, setTitle] = useState((data != undefined) ? data.title : '');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
  const navigate = useNavigate();

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess_1 = "Сторінку оновлено";
  const popupTextSuccess_2 = "Сторінку створено";
  const popupTextWarning_1 = "Сторінку не оновлено";
  const popupTextWarning_2 = "Сторінку не створено";

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        title: title,
        synonym: synonym,
        content: editorRef.current.getContent(),
        active: 'on',
        images: mainImg,
      };
      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          console.log(response);
          if (response.status === 200) {
            popup.toastifySuccess(popupTextSuccess_1);
          } else {
            popup.toastifyWarning(popupTextWarning_1);
          }
        });
      } else {
        AxiosInstance.post(urlApi, article).then((response) => {
          console.log(response);
          if (response.status === 201) {
            popup.toastifySuccess(popupTextSuccess_2);
            navigate(`${urlRouter}/update/${response.data.id}`);
          } else {
            popup.toastifyWarning(popupTextWarning_2);
          }
        });
      }
    }
  }


  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Заголовок</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Синонім</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={synonym} onChange={(e) => setSynonym(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Головне зображення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={mainImg} onChange={(e) => setmainImg(e.target.value)} />
          <Link to="/media" target='_blanck'>перейти до медіа</Link>
        </div>
        <Alert variant='info'>Для коректного відображення на сайті текст має бути без зайвих стилів. Для очищення тексту копіюйте його з "Блокнот" або з "Notepad++", або печатати в редакторі нижче</Alert>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format tools',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <CButton className="mb-2" type="submit" color="success">Зберегти</CButton>
      </CForm>

      {(data != undefined) ? <PagesCategories page_id={data.id} toastifySuccess={popup.toastifySuccess} /> : ''}
    </>
  );
};

export default pagesForm;
