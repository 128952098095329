import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormInput } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert';
import AsyncSelect from 'react-select/async';
import * as dayjs from 'dayjs';

const urlApi = '/news';
const urlApi_2 = '/categories';
const urlRouter = '/news';

const page = 1;
const itemsPerPage = 30;
const pageSize = 30;

export async function LoaderNews({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}&itemsPerPage=${itemsPerPage}`).then((response) => {
    return response;
  });
  return res;
};


const News = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [categoriesFilterSearch, setCategoriesFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  // Вибрана категорія
  const [selectedCategories, setSelectedCategories] = useState(null);
  // Всі категорії з API
  const [categories, setCategories] = useState(false);
  // Категорія залогіненого адміна (Бізнес, ВПО)
  const categoryNameBusiness = "Бізнес";
  const categoryNameVPO = "ВПО";
  // Назва дозволена категорія в залежності від ролі
  const [allowedCategoryName, setAllowedCategoryName] = useState(false);
  // Дозволена категорія в залежності від ролі, яка вибрана з API - для запиту за новинами
  const [allowedCategoryFromAPI, setAllowedCategoryFromAPI] = useState(false);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Новину видалено";

  const [userRole, setUserRole] = useState(false);
  // const [userRole, setUserRole] = useState("ROLE_VPO");
  // const [userRole, setUserRole] = useState("ROLE_BUSINESS");
  // const [userRole, setUserRole] = useState("ROLE_ADMIN");
  if (!userRole) {
    setUserRole(localStorage.getItem('user_role'));
  }

  // Запит за всіма категоріями, якщо роль не Адмін ("ROLE_ADMIN") та визначити назву дозволеної категорії
  useEffect(() => {
    // console.log('userRole', userRole);
    if (userRole !== "ROLE_ADMIN" && userRole !== "ROLE_CONTENT" && userRole !== "ROLE_NEWS") {
      // Запит за всіма категоріями
      AxiosInstance.get(`${urlApi_2}?page=${page}`).then((response) => {
        // console.log("response categories", response);
        setCategories(response.data['hydra:member']);
      });

      // Визначити дозволену категорію
      if (userRole === "ROLE_VPO") {
        setAllowedCategoryName(categoryNameVPO);
      }
      if (userRole === "ROLE_BUSINESS") {
        setAllowedCategoryName(categoryNameBusiness);
      }
    }
  }, [userRole]);

  // Вибрати дозволену категорію, якщо роль не Адмін ("ROLE_ADMIN")
  useEffect(() => {
    // console.log('categories', categories);
    if (categories != false && allowedCategoryName != false) {
      // Визначити дозволену категорію
      setAllowedCategoryFromAPI(categories.find(category => category.name === allowedCategoryName));
    }
  }, [categories, allowedCategoryName]);


  // Запит за новинами по дозволеній категорії
  useEffect(() => {
    // console.log('allowedCategoryFromAPI', allowedCategoryFromAPI);
    getRowsTable();
  }, [allowedCategoryFromAPI]);

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  };

  // Запит за новинами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = {
      page: p,
      itemsPerPage: itemsPerPage,
    };
    if (nameFilterSearch != '')
      request['name'] = nameFilterSearch;

    if (userRole === "ROLE_ADMIN" || userRole === "ROLE_CONTENT" || userRole === "ROLE_NEWS") {
      if (categoriesFilterSearch != '')
        request['categories.id'] = categoriesFilterSearch;
      // console.log("request", request);
      AxiosInstance.get(urlApi, { params: request }).then((response) => {
        setRows(response.data['hydra:member']);
        setRowCountState(response.data['hydra:totalItems']);
      });
    } else {
      if (allowedCategoryFromAPI != '')
        request['categories.id'] = allowedCategoryFromAPI.id;
      // console.log("request", request);
      AxiosInstance.get(urlApi, { params: request }).then((response) => {
        setRows(response.data['hydra:member']);
        setRowCountState(response.data['hydra:totalItems']);
      });
    }
  };

  // Завантажити категорії
  const loadCategories = () => {
    return AxiosInstance.get('/categories', { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
  };

  // Вибрана категорія для відображення в полі пошуку та подальшій передачі для фільтревання новин
  useEffect(() => {
    if (selectedCategories != null) {
      // console.log("selectedCategories", selectedCategories);
      setCategoriesFilterSearch(selectedCategories.value);
    };
  }, [selectedCategories]);

  // Після вибору категорії зробити запит за товарами категорії та оновити товари для вибору
  useEffect(() => {
    if (categoriesFilterSearch != "") {
      getRowsTable();
    }
  }, [categoriesFilterSearch]);

  // Функція для вибору категорії з списку
  const handleChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const disabledButton = (event) => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || categoriesFilterSearch != "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, categoriesFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleCategoriesFilterChange = (event) => {
    setCategoriesFilterSearch(event.target.value);
    disabledButton(event);
  }
  // ----- End handleFilterChange -----

  // Фільтрування по name та категорії
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setCategoriesFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    { field: 'name', headerName: 'Назва', width: 585 },
    { field: 'newsShot', headerName: 'Опис', width: 200 },
    {
      field: 'categories',
      headerName: 'Категорія',
      // type: 'date',
      width: 80,
      renderCell: (params) => {
        console.log('params.row', params.row);
        return <span>{params.row.categories[0]?.name}</span>;
      }
    },
    {
      field: 'date',
      headerName: 'Дата',
      type: 'date',
      width: 90,
      renderCell: (params) => {
        // console.log('params.row', params.row);
        return <span>{dayjs(params.row.date).format('DD.MM.YYYY')}</span>;
      }
    },
    {
      field: "action",
      headerName: "Редагувати",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <>

      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">

            <CAccordion flush>
              <CAccordionItem>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={4} className='filter-field'>
                          <CFormInput placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2" onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} />
                        </CCol>
                        {(userRole === "ROLE_ADMIN" || userRole === "ROLE_CONTENT" || userRole === "ROLE_NEWS") &&
                          <CCol md={4}>
                            <AsyncSelect
                              cacheOptions
                              loadOptions={loadCategories}
                              defaultOptions
                              value={selectedCategories}
                              onChange={handleChange}
                              className="flex-grow-1 me-1 mb-3"
                              onKeyDown={keyDown}
                              placeholder="Оберіть категорію"
                            />
                          </CCol>
                        }
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
            <CCardHeader>
              <strong>Новини</strong> <small>Basic example</small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to="/news/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default News;