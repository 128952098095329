import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import Select from 'react-select';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const itemsPerPage = 30;
const urlApi = '/users';
const urlRouter = '/users';

const role = localStorage.getItem('user_role');
// const userData = JSON.parse(localStorage.getItem('user_data'));

export async function LoaderUsers({ params }) {
  let query = `page=${page}`;
  // if (role == 'ROLE_MANAGER')
  //   query += `&accounts.manager.id=${userData.id}`;

  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    return response;
  });
  return res;
}

const roleList = {
  ROLE_ADMIN: 'Адміністратор',
  // ROLE_MANAGER: 'Менеджер',
  ROLE_BUSINESS: 'Адміністратор-бізнес',
  ROLE_VPO: 'Адміністратор-ВПО',
  ROLE_CONTENT: 'Адміністратор-контенту',
  ROLE_NEWS: 'Адміністратор-новин',
  ROLE_FORMS: 'Адміністратор-форм',
  // ROLE_CLIENT: 'Клієнт',
  ROLE_USER: 'Без ролі',
};

const Users = () => {
  const { data } = useLoaderData();
  // Для різних запитів на API в залежності чи фільтруються дані чи ні
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [userTypeFilterSearch, setUserTypeFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [btnFilterDisabled, setBtnFilterDisabled] = useState(true);
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Користувача видалено";
  // Кількість користувачів на сторінці при пагінації
  const initPageSize = 30;
  const [pageSize_2, setPageSize_2] = useState(initPageSize);
  const domen = "https://api-lova-support-business.inneti.net";
  // const domen = "https://api.cp-oplich.com/";
  const [btnDownloadDisabled, setBtnDownloadDisabled] = useState(true);

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  // useEffect(() => {
  //   console.log('rows', rows);
  //   console.log('rowCountState', rowCountState);
  //   console.log('pageSize_2', pageSize_2);
  // }, [rows, rowCountState, pageSize_2]);

  // Типи користувасів
  const usersTypeList = [
    { value: 'Типи користувачів', label: 'Виберіть Тип', isDisabled: true },
    { value: 'Lviv', label: 'Мешканець Львівщини' },
    { value: 'Vpo', label: 'ВПО' },
    { value: 'business', label: 'Бізнес' },
  ];

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // useEffect(() => {
  //   console.log('isFilterOn', isFilterOn);
  // }, [isFilterOn]);

  // Зробити запит за користувачами
  useEffect(() => {
    getRowsTable();
  }, [isFilterOn]);

  // Запит за користувачами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = {
      page: p,
      itemsPerPage: itemsPerPage,
      s: nameFilterSearch
    };
    if (userTypeFilterSearch != '') {
      request['userType'] = userTypeFilterSearch.value;
    }
    // console.log("request", request);
    if (!isFilterOn) {
      // console.log('isFilterOn = false', isFilterOn);
      AxiosInstance.get(urlApi, { params: request }).then((response) => {
        // console.log('filter Off response get', response);
        setRows(response.data['hydra:member']);
        setRowCountState(response.data['hydra:totalItems']);
        setPageSize_2(initPageSize);
      });
    } else {
      // console.log('isFilterOn = true', isFilterOn);
      AxiosInstance.get(`user/search`, { params: request }).then((response) => {
        // console.log('filter On response get', response);
        setRows(response.data);
        setRowCountState(response.data.length);
        setPageSize_2(response.data.length);
      });
    }
  };

  // Змінити сторінку з користувачами = зробити запит
  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setBtnFilterDisabled(false);
    } else {
      setBtnFilterDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || userTypeFilterSearch != "") {
      setBtnFilterDisabled(false);
    };
  }, [nameFilterSearch, userTypeFilterSearch]);

  // Після вибору категорії зробити запит за товарами категорії та оновити товари для вибору
  useEffect(() => {
    if (userTypeFilterSearch !== "" && !isFilterOn) {
      setIsFilterOn(true);
    } else if (userTypeFilterSearch !== "") {
      getRowsTable();
    }
  }, [userTypeFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleUserTypeChange = (userTypeFilterSearch) => {
    setUserTypeFilterSearch(userTypeFilterSearch);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по userName, firstName та lastName
  const handleFilterSearch = () => {
    if (!isFilterOn) {
      setIsFilterOn(true);
    } else {
      getRowsTable();
    }
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setUserTypeFilterSearch("");
    setBtnFilterDisabled(true);
    setIsFilterOn(false);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(urlApi + '/' + row.id, header).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'email', headerName: 'email', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.email}</Link>;
      }
    },
    { field: 'firstName', headerName: 'Ім\'я', width: 150 },
    { field: 'lastName', headerName: 'Прізвище', width: 150 },
    { field: 'phone', headerName: 'Телефон', width: 110 },
    {
      field: "role",
      headerName: "Роль",
      sortable: false,
      width: 170,
      renderCell: (params) => {
        let role = '';
        if (typeof params.row.roles == 'object')
          role = params.row.roles.map((e) => {
            if (e != 'ROLE_USER')
              return roleList[e];
          });
        return role;
      }
    },
    // { field: 'address', headerName: 'Адреса', width: 200 },
    { field: 'status', headerName: 'Статус', width: 120 },
    // { field: 'codeUser', headerName: 'codeUser', width: 200 },
    { field: 'codeManager', headerName: 'codeManager', width: 150 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    // {
    //   field: "delete",
    //   headerName: "Видалити",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <CButton color="danger" variant="outline" onClick={() => {
    //       Deleted(params.row)
    //     }
    //     }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
    //   }
    // },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !btnFilterDisabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  // Функція для виведення popup про завантаження користувачів
  const downloadUsers = () => {
    // alert("Користувачів завантажено");
    popup.toastifySuccess("Користувачі завантажуються");
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CAccordion flush>
              <CAccordionItem>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={5} className='filter-field'>
                          <CFormInput placeholder="name" id="nameFilterSearch" aria-label="Пошук" className="me-2" onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} />
                        </CCol>
                        <CCol md={5} className='filter-field'>
                          <Select
                            placeholder="Тип користувача"
                            options={usersTypeList}
                            value={userTypeFilterSearch}
                            onChange={handleUserTypeChange}
                            className="flex-grow-1 me-2"
                            onKeyDown={keyDown}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={btnFilterDisabled}>Пошук</CButton>
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>

            <CAccordion flush>
              <CAccordionItem>
                <CAccordionHeader><strong>Завантажити користувачів</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={3}>
                      <Link to={`${domen}/api/users/export_csv`}>
                        <CButton className="me-2" type="submit" color="secondary" onClick={downloadUsers}
                        // disabled={btnDownloadDisabled}
                        >
                          Завантажити
                        </CButton>
                      </Link>
                    </CCol>
                  </CRow>
                </CAccordionBody >
              </CAccordionItem >
            </CAccordion>

            <CCardHeader >
              <strong>Користувачі</strong> <small></small>
            </CCardHeader >
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  // pageSize={30}
                  pageSize={pageSize_2}
                  // rowsPerPageOptions={[30]}
                  rowsPerPageOptions={[pageSize_2]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard >
        </CCol >
      </CRow >
    </>
  );
};

export default Users;