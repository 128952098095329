import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import { CForm, CFormLabel, CFormInput, CButton, CFormCheck } from '@coreui/react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const urlRouter = '/users';

const getBusyList = [
  { value: 'Зайнятість', label: 'Виберіть зайнятість', isDisabled: true },
  { value: 'Працюю', label: 'Працюю' },
  { value: 'Не працюю', label: 'Не працюю' }
];

const ageList = [
  { value: 'Вік', label: 'Виберіть вік', isDisabled: true },
  { value: '18-30', label: '18-30' },
  { value: '30-50', label: '30-50' },
  { value: '50-60', label: '50-60' }
];

const genderList = [
  { value: 'Стать', label: 'Виберіть стать', isDisabled: true },
  { value: 'Чоловік', label: 'Чоловік' },
  { value: 'Жінка', label: 'Жінка' },
];

const rolesList = [
  { value: 'Роль', label: 'Виберіть роль', isDisabled: true },
  { value: 'ROLE_USER', label: 'Без ролі' },
  { value: 'ROLE_ADMIN', label: 'Адміністратор' },
  { value: 'ROLE_CONTENT', label: 'Адміністратор-контенту' },
  { value: 'ROLE_BUSINESS', label: 'Адміністратор-бізнес' },
  { value: 'ROLE_VPO', label: 'Адміністратор-ВПО' },
  { value: 'ROLE_NEWS', label: 'Адміністратор-новин' },
  { value: 'ROLE_FORMS', label: 'Адміністратор-форм' },
];

// Компонент для створення користувача з типом "Мешканець Львівщини"
const UsersFormLviv = (props) => {
  // console.log('props.role', props.role);
  // console.log('UsersFormLviv props', props);

  const objOrderData = {
    username: '',
    firstName: '',
    lastName: '',
    plainPassword: '',
    phone: '',
    employed: '',
    email: '',
    age: '',
    exDirector: '',
    currentDirector: '',
    gender: '',
    address: '',
    supportType: [],
    roles: [],
    userType: props.userType,
  };

  // Чи обов'язково заповнювати поле з перолем 
  const isRequiredPassword = (props.data == undefined) ? true : false;
  // Значення, які передадуться в api
  const [values, setValues] = useState(objOrderData);
  // Значення для коректного відображення Select
  const [selectItems, setSelectItems] = useState({
    employed: getBusyList[0],
    age: ageList[0],
    gender: genderList[0],
    // roles: [
    //   rolesList[0]
    // ],
    roles: rolesList[0].value,
  });
  // Для відслідковування checkbox для "Матеріальної підтримки"
  const [isMentoringSupport, setIsMentoringSupport] = useState((props.data != undefined && props.data.supportType.length > 0) ? true : false);
  // Для анімації Select
  const animatedComponents = makeAnimated();
  // Чи адміністратор (для надання права змінювати ролі)
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (props.role != undefined && (props.role === "ROLE_ADMIN" || props.role === "ROLE_CONTENT" || props.role === "ROLE_FORMS")) {
      setIsAdmin(true);
    }
  }, [props.role]);

  // useEffect(() => {
  //   console.log('values', values);
  //   console.log('selectItems', selectItems);
  // }, [values, selectItems]);

  // Для відслідковування чи потрібна підтримка
  useEffect(() => {
    // console.log('isMentoringSupport', isMentoringSupport);
    if (isMentoringSupport) {
      setValues({
        ...values,
        "supportType": ["Матеріальна"]
      });
    } else {
      setValues({
        ...values,
        "supportType": []
      });
    }
  }, [isMentoringSupport]);

  // Заповнити поля при users/update/
  useEffect(() => {
    if (props.data != undefined) {
      setValues({
        ...values,
        username: props.data.username,
        firstName: props.data.firstName,
        lastName: props.data.lastName,
        plainPassword: props.data.plainPassword,
        phone: props.data.phone,
        employed: props.data.employed,
        email: props.data.email,
        age: props.data.age,
        exDirector: props.data.exDirector,
        currentDirector: props.data.currentDirector,
        gender: props.data.gender,
        address: props.data.address,
        supportType: props.data.supportType,
        roles: props.data.roles,
        userType: props.userType,
        active: 'on',
      });

      let employedInit = getBusyList.map((v, k) => {
        if (props.data.employed == v.value)
          return v;
      });
      let ageInit = ageList.map((v, k) => {
        if (props.data.age == v.value)
          return v;
      });
      let genderInit = genderList.map((v, k) => {
        if (props.data.gender == v.value)
          return v;
      });
      let rolesInit = rolesList.filter((roleItem) => {
        return props.data.roles.includes(roleItem.value);
      });

      setSelectItems({
        ...selectItems,
        employed: employedInit,
        age: ageInit,
        gender: genderInit,
        roles: rolesInit,
      });
    }
  }, [props.data]);

  // Функція для зміни значень Input
  const handleInputChange = (e) => {
    // console.log(e);
    // console.log("e.target", e.target);
    // const { name, value, type } = e.target;
    const { name, value } = e.target;

    if (name === "email") {
      // console.log('name', name);
      // для дублювання username(login) з email
      setValues({
        ...values,
        [name]: value,
        username: value
      })
    } else {
      setValues({
        ...values,
        // [name]: type === "number" ? parseFloat(value, 10) : value
        [name]: value,
      });
    };
  };

  // Функція для зміни значень Select
  const handleChange = (value, name) => {
    // console.log('handleChange value', value);

    if (name === 'roles' || name === 'supportType') {
      // if (name === 'roles') {
      let roles = [];
      value.map((role) => {
        roles.push(role.value);
      });
      setValues({
        ...values,
        // [name]: [value.label]
        [name]: roles
      });
    } else {
      setValues({
        ...values,
        [name]: value.value
      });
    }

    setSelectItems({
      ...selectItems,
      [name]: value
    });
  };

  // Функція для відправки форми
  const handleSubmit = event => {
    event.preventDefault();

    let v = true;
    let vError = '';

    // if (values.roles.length == 0) {
    //   v = false;
    //   vError += "-> Роль\n";
    // }
    if (values.age == '') {
      v = false;
      vError += "-> Вік\n";
    }
    if (values.gender == '') {
      v = false;
      vError += "-> Стать\n";
    }
    if (values.employed == '') {
      v = false;
      vError += "-> Зайнятість\n";
    }

    if (!v) {
      alert("Ви не заповнили обовязкові поля:\n" + vError);
    }

    // Для надсилання запиту для створення користувача
    if (v) {
      // console.log('values post', values);

      // Передати дані про користувача на верх для запиту на створення 
      props.setDataToApi(values);
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3 mt-4">
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">username</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" name='username' value={values.username} onChange={handleInputChange} required />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" name='email' value={values.email} onChange={handleInputChange}
            pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
            title="Будь ласка, введіть коректний Email у форматі mail@gmail.com і тільки латинськими літерами"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" name='firstName' value={values.firstName} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Прізвище</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" name='lastName' value={values.lastName} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">plainPassword</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput5" name='plainPassword' value={values.plainPassword} onChange={handleInputChange} required={isRequiredPassword} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" name='phone' value={values.phone} onChange={handleInputChange}
            pattern="^\d{10}$|^\d{12}$"
            title="Будь ласка, введіть номер телефону у форматі 10 або 12 цифр"
            required />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput7">Роль</CFormLabel>
          <Select
            value={selectItems.roles}
            components={animatedComponents}
            isMulti
            name="roles"
            options={rolesList}
            onChange={(value) => { handleChange(value, 'roles') }}
            isDisabled={!isAdmin}
          />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput7">Вік</CFormLabel>
          <Select options={ageList} value={selectItems.age} onChange={(value) => { handleChange(value, 'age') }} />
        </div>
        <div className='mb-3'>
          <CFormLabel htmlFor="exampleFormControlInput8">Стать</CFormLabel>
          <Select options={genderList} value={selectItems.gender} onChange={(value) => { handleChange(value, 'gender') }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput9">Зайнятість</CFormLabel>
          <Select options={getBusyList} value={selectItems.employed} onChange={(value) => { handleChange(value, 'employed') }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput10">Попередній роботодавець</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput10" name='exDirector' value={values.exDirector} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput11">Діючий роботодавець</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput11" name='currentDirector' value={values.currentDirector} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput12">Адреса проживання</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput12" name='address' value={values.address} onChange={handleInputChange} required />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput13" label="Матеріальна підтримка" checked={isMentoringSupport} onChange={() => setIsMentoringSupport(!isMentoringSupport)} />
        </div>


        {/* <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format tools',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default UsersFormLviv;