import React, { useEffect, useRef, useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import UsersFormLviv from 'src/components/users/UsersFormLviv';
import UsersFormVPO from 'src/components/users/UsersFormVPO';
import UsersFormBusiness from 'src/components/users/UsersFormBusiness';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { CFormCheck } from '@coreui/react';

const urlApi = '/users';
const urlRouter = '/users';
const role = localStorage.getItem('user_role');

// Компонент для створення користувача
const UsersCreateForm = () => {
  // Тип користувача ("Lviv", "Vpo", "business")
  const [userType, setUserType] = useState("");
  // Дані для відправлення на api
  const [dataToApi, setDataToApi] = useState(false);
  const navigate = useNavigate();
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Користувача створено";
  const popupTextWarning = "Користувача не створено";

  // Запит для створення користувача
  useEffect(() => {
    // console.log('dataToApi', dataToApi);
    if (dataToApi != false) {
      AxiosInstance.post(`${urlApi}`, dataToApi).then((response) => {
        if (response.status === 201) {
          alert("Користувача створено");
          popup.toastifySuccess(popupTextSuccess);
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          // alert("Користувача не створено");
          popup.toastifyWarning(popupTextWarning);
        }
      });
    }
  }, [dataToApi]);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Вибір типу користувача */}
      <div className='mb-4'>
        <h2 className="mb-4">Реєстрація користувача</h2>
        <div className='d-flex'>
          <div className="mb-3 me-5">
            <CFormCheck id="exampleFormControlInput1" label="Мешканець Львівщини" checked={userType === "Lviv"} onChange={() => setUserType("Lviv")} />
          </div>
          <div className="mb-3 me-5">
            <CFormCheck id="exampleFormControlInput2" label="ВПО" checked={userType === "Vpo"} onChange={() => setUserType("Vpo")} />
          </div>
          <div className="mb-3">
            <CFormCheck id="exampleFormControlInput4" label="Бізнес" checked={userType === "business"} onChange={() => setUserType("business")} />
          </div>
        </div>
      </div>

      <>
        {/* Тип користувача "Мешканець Львівщини" */}
        {
          (userType === "Lviv") &&
          <>
            <h3>Мешканець Львівщини</h3>
            <UsersFormLviv userType={userType} setDataToApi={setDataToApi} role={role} />
          </>
        }

        {/* Тип користувача "ВПО" */}
        {
          (userType === "Vpo") &&
          <>
            <h3>ВПО</h3>
            <UsersFormVPO userType={userType} setDataToApi={setDataToApi} />
          </>
        }

        {/* Тип користувача "Бізнес" */}
        {
          (userType === "business") &&
          <>
            <h3>Бізнес</h3>
            <UsersFormBusiness userType={userType} setDataToApi={setDataToApi} />
          </>
        }
      </>
    </>
  );
};

export default UsersCreateForm;