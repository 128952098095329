import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import NewsCategories from 'src/components/news/NewsCategories';
import { Alert } from 'react-bootstrap';

const urlApi = '/news';
const urlApi_2 = '/categories';
const urlRouter = '/news';
// const role = localStorage.getItem('user_role');

const page = 1;

export async function LoaderNewsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};


const newsForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined) ? data.newsShot : '');
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [date, setDate] = useState((data != undefined) ? data.data : new Date().toISOString());
  const [MyData, setMyData] = useState(data);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess_1 = "Оновлено";
  const popupTextSuccess_2 = "Створено";
  const popupTextAddSuccess = "Категорію додано";
  const popupTextWarning_1 = "Не оновлено";
  const popupTextWarning_2 = "Не створено";
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Всі категорії з API
  const [categories, setCategories] = useState(false);
  // Категорія залогіненого адміна (Бізнес, ВПО)
  const categoryNameBusiness = "Бізнес";
  const categoryNameVPO = "ВПО";
  const categoryNameVeterans = "Ветерани";
  // Назва дозволена категорія в залежності від ролі
  const [allowedCategoryName, setAllowedCategoryName] = useState(false);
  // Дозволена категорія в залежності від ролі, яка вибрана з API - для запиту за новинами
  const [allowedCategoryFromAPI, setAllowedCategoryFromAPI] = useState(false);

  const [userRole, setUserRole] = useState(false);
  // const [userRole, setUserRole] = useState("ROLE_VPO");
  // const [userRole, setUserRole] = useState("ROLE_BUSINESS");
  // const [userRole, setUserRole] = useState("ROLE_ADMIN");
  // const [userRole, setUserRole] = useState("ROLE_CONTENT");
  if (!userRole) {
    setUserRole(localStorage.getItem('user_role'));
  }

  // Запит за всіма категоріями, якщо роль не Адмін ("ROLE_ADMIN") та визначити назву дозволеної категорії
  useEffect(() => {
    // console.log('userRole', userRole);
    if (userRole !== "ROLE_ADMIN" && userRole !== "ROLE_CONTENT" && userRole !== "ROLE_NEWS") {
      // Запит за всіма категоріями
      AxiosInstance.get(`${urlApi_2}?page=${page}`).then((response) => {
        // console.log("response categories", response);
        setCategories(response.data['hydra:member']);
      });

      // Визначити дозволену категорію
      if (userRole === "ROLE_VPO") {
        setAllowedCategoryName(categoryNameVPO);
      }
      if (userRole === "ROLE_BUSINESS") {
        setAllowedCategoryName(categoryNameBusiness);
      }
    }
  }, [userRole]);

  // Вибрати дозволену категорію, якщо роль не Адмін  ("ROLE_ADMIN") та не Контент-адмін ("ROLE_CONTENT")
  useEffect(() => {
    // console.log('categories', categories);
    if (categories != false && allowedCategoryName != false) {
      // Визначити дозволену категорію
      setAllowedCategoryFromAPI(categories.find(category => category.name === allowedCategoryName));
    }
  }, [categories, allowedCategoryName]);

  // Функція для кнопки "Зберегти"
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
        date: date,
        // active: ""
      };

      // Для оновлення новини
      if (data != undefined) {
        // console.log('News put article', article);
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          // console.log(response);
          if (response.status === 200) {
            popup.toastifySuccess(popupTextSuccess_1);
          } else {
            popup.toastifyWarning(popupTextWarning_1);
          }
        });
      } else {
        // Для створення новини
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log("response post news", response);
          if (response.status === 201) {
            if (userRole !== "ROLE_ADMIN" && userRole !== "ROLE_CONTENT" && userRole !== "ROLE_NEWS") {
              // Додати нову категорію

              // Вибрана нова категорія
              // let newCategories = [`api/categories/${selectedOption.value}`];
              let newCategories = [`api/categories/${allowedCategoryFromAPI.id}`];
              // Дані, які підуть із запитом
              let article = {
                categories: newCategories
              };

              // console.log('newCategories', article);

              // Додати категорію для новини
              AxiosInstance.put(`/news/${response.data.id}`, article).then((response) => {
                // console.log("response put category", response);
                if (response.status == 200) {
                  // Popup про успішні зміни
                  popup.toastifySuccess(popupTextAddSuccess);
                  // alert("Дані оновлено");
                  // getParent();
                  navigate(`${urlRouter}/update/${response.data.id}`);
                }
              });
              popup.toastifySuccess(popupTextSuccess_2);

            } else {
              // для "ROLE_ADMIN" та "ROLE_CONTENT"
              popup.toastifySuccess(popupTextSuccess_2);
              navigate(`${urlRouter}/update/${response.data.id}`);
            }
          } else {
            // для "ROLE_VPO" та "ROLE_BUSINESS"
            popup.toastifyWarning(popupTextWarning_2);
          }
        });
      }
    }
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
        </div>
        <Alert variant='info'>Для коректного відображення на сайті текст має бути без зайвих стилів. Для очищення тексту копіюйте його з "Блокнот" або з "Notepad++", або набирати текст в редакторі нижче</Alert>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.newsFull : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format tools',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {/* Категорії */}
      {
        (MyData != undefined) &&
        <NewsCategories news_id={MyData.id} toastifySuccess={popup.toastifySuccess} userRole={userRole} allowedCategoryFromAPI={allowedCategoryFromAPI} />
      }
    </>
  );
};

export default newsForm;