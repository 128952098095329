import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
// import { Link } from 'react-router-dom';
import Select from 'react-select';

// Додавання категорій до новини
const NewsCategories = (props) => {
    // console.log("NewsCategories props", props);
    // props.news_id - id-новини
    // console.log('props.role NewsCategories', props.role);

    // Категорії, які додані до новини
    const [catItem, setCatItem] = useState([]);
    // Категорії, які додані до новини, записані у форматі PUT (`api/categories/id`) для відправлення
    const [oldCat, setOldCat] = useState([]);
    // Вибрана категорія з пошуку
    const [selectedOption, setSelectedOption] = useState(null);
    // Текст для popup
    const popupTextAddSuccess = "Категорію додано";
    const popupTextDeleteSuccess = "Категорію видалено";
    // Категорії для ROLE_BUSINESS та ROLE_VPO, які можна додати
    const [categoriesForRole, setCategoriesForRole] = useState(false);

    let i = 0;
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    // Формвання категорій для ROLE_BUSINESS та ROLE_VPO, які можна додати
    useEffect(() => {
        // console.log('props.userRole', props.userRole);
        // console.log('props', props);
        if (props.userRole !== undefined && props.userRole !== "ROLE_ADMIN" && props.userRole !== "ROLE_CONTENT" && props.userRole !== "ROLE_NEWS" && props.allowedCategoryFromAPI !== undefined) {
            let propsCategories = [];
            // propsCategories.push(props.categoryListOfPrograms);
            propsCategories.push(props.allowedCategoryFromAPI);
            const newCategoriesForRole = propsCategories.map(category => {
                return {
                    value: category.id,
                    label: category.name
                }
            })
            setCategoriesForRole(newCategoriesForRole);
        }
    }, [props])

    // Завантаження всіх категорій для вибору з випадаючого списку якщо ROLE_ADMIN
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/categories?name=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log('response categories', response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Запит за категоріями, які вже додані до новини
    const getParent = async () => {
        AxiosInstance.get(`/categories?news.id=${props.news_id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response get news", response);
            setCatItem(response.data)
        });
    }
    useEffect(() => {
        getParent();
    }, [props]);

    // console.log("catItem для новини", catItem)

    // Заповнити категорії, які додані до новини, записані у форматі PUT (`api/news/id`) для відправлення
    useEffect(() => {
        if (catItem.length > 0) {
            let oldCategories = [];
            // Наповнити масив з категорями, перетвеними у формат для API
            catItem.map(el => oldCategories.push(`api/categories/${el.id}`));
            // console.log("oldCategories", oldCategories);
            setOldCat(oldCategories);
        }
        // console.log("catItem.categories", catItem);
    }, [catItem]);

    // Додати нову категорію
    const handleSave = () => {
        // Перевірка, чи вибрана категорія вже належить до даного новини
        let enters = catItem.length > 0 ? catItem.some(el => el.id == selectedOption.value) : false;
        if (enters) {
            alert("Вибрана категорія вже належить до даної новини");
        } else {
            // Вибрана нова категорія
            let newCategories = [`api/categories/${selectedOption.value}`];
            // Об'єднати старі категорії (якщо є) і нову категорію
            let categories = oldCat.length > 0 ? oldCat.concat(newCategories) : newCategories;
            // Дані, які підуть із запитом
            let article = {
                categories: categories
            };

            // Оновити категорії для новини
            AxiosInstance.put(`/news/${props.news_id}`, article).then((response) => {
                // console.log("response put", response);
                if (response.status == 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextAddSuccess);
                    // alert("Дані оновлено");
                    getParent();
                }
            });
        };
    };

    // Видалити категорію від новини
    const deleteCategory = (id) => {
        let categories = [];
        // Відфільтрувати категорію, яку потрібно видалити
        let filterCategories = catItem.filter(el => el.id !== id);
        // Наповнити масив з категорями, перетвеними у формат для API
        filterCategories.map(el => categories.push(`api/categories/${el.id}`));
        // Дані, які підуть із запитом
        let article = {
            categories: categories
        };

        // Оновити категорії для новини
        AxiosInstance.put(`/news/${props.news_id}`, article).then((response) => {
            // console.log("response put", response);
            if (response.status == 200) {
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.toastifySuccess(popupTextDeleteSuccess);
                getParent();
            }
        });
    };

    // Видалити категорію від новини
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log("delete row id", row);
                        deleteCategory(row);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <>
            <div className="row">
                <div className="col"><h3>Категорії</h3></div>
                {/* Список з вваріантами категорій */}
                {
                    (props.userRole === "ROLE_ADMIN" || props.userRole === "ROLE_CONTENT" || props.userRole === "ROLE_NEWS") ?
                        <div className="col d-flex">
                            {/* Список з вваріантами категорій для ROLE_ADMIN (Адміністратор) */}
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                value={selectedOption}
                                onChange={handleChange}
                                className="flex-grow-1 me-2"
                                placeholder="Оберіть категорію"
                            />
                            <CButton onClick={handleSave} className="mb-2" color="secondary">+</CButton>
                        </div> :
                        <div className="col d-flex">
                            {/* Список з вваріантами категорій для ROLE_BUSINESS та ROLE_VPO */}
                            <Select
                                options={categoriesForRole}
                                value={selectedOption}
                                onChange={handleChange}
                                className="flex-grow-1 me-2"
                                placeholder="Оберіть категорію"
                            />
                            <CButton onClick={handleSave} className="mb-2" color="secondary">+</CButton>
                        </div>
                }
            </div>

            {/* Список з доданими категоріями */}
            {
                (catItem.length > 0) ?
                    <CListGroup className="mb-3">
                        {
                            catItem.map((v, k) => {
                                i++;
                                return (
                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        {/* <Link to={`/news/update/${v.id}`} >{`${i})`} {v.name}</Link> */}
                                        {`${i})`} {v.name}
                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v.id) }}>
                                            Видалити
                                        </CButton>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup>
                    :
                    <p className="ml-3">Категорій не знайдено</p>
            }
        </>
    );
}

export default NewsCategories;